import React from "react"
import { graphql } from "gatsby"
import {
  Container,
  Content,
  ContentCard,
  FeatureImage,
  Pagination,
  Seo,
} from "../components"
import { H1, P } from "../elements"
import OG_image from '../img/og_image.jpg'

// Read the values they come in from gatsby-node.js
// data = data from graphql query
const allPosts = ({ pageContext, data }) => {
  const { currentPage, numPages } = pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  // if page 2 - 1 = 1
  const prevPage = currentPage - 1 === 1 ? "/" : `/${currentPage - 1}`
  const nextPage = `/${currentPage + 1}`
  const posts = data.allMdx.edges

  return (
    <Container>
      <Seo image={OG_image} />
      <FeatureImage />
      <Content>
        <H1 textAlign="center" margin=" 0 1rem 0">
          Welcome to Sausage KL Blog <span role="img" aria-label="Smile">😊</span>
        </H1>
        <P color="light2" textAlign="center"></P>
        {posts.map(post => (
          <ContentCard
            key={post.node.frontmatter.slug}
            date={post.node.frontmatter.date}
            title={post.node.frontmatter.title}
            excerpt={post.node.frontmatter.excerpt}
            slug={post.node.frontmatter.slug}
            readingTime={post.node.fields.readingTime.text}
          />
        ))}
      </Content>
      {/* Pagination */}
      <Pagination
        isFirst={isFirst}
        isLast={isLast}
        prevPage={prevPage}
        nextPage={nextPage}
      />
    </Container>
  )
}

export default allPosts

// graphql query
export const pageQuery = graphql`
  query AllPostsQuery($skip: Int!, $limit: Int!) {
    allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
            title
            excerpt
          }
          fields {
            readingTime {
              text
            }
          }
        }
      }
    }
  }
`
